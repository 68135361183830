<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col cols="12">
            <b-input-group>
              <b-form-input
                v-model="search"
                placeholder="Servis Kartı Ara"
              />
              <b-input-group-append>
                <b-button
                  v-if="search !== ''"
                  variant="warning"
                  @click="resetSearch"
                ><FeatherIcon icon="XIcon" /></b-button>
                <b-button
                  variant="primary"
                  :disabled="search === ''"
                  @click="searchData"
                ><FeatherIcon icon="SearchIcon" /></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(created)="data">
              <div class="text-left">
                {{ moment(data.item.startDate).format('LL') !== 'Invalid date'? moment(data.item.startDate).format('LL') : '-' }}
              </div>
            </template>
            <template #cell(control)="data">
              <div class="text-right">
                <b-button
                  variant="primary"
                  size="sm"
                  :to="'/app/services/view/' + data.item.id"
                >
                  <FeatherIcon icon="EyeIcon" />
                  Görüntüle
                </b-button>
              </div>
            </template>
          </b-table></b-card-text>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Services',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'service_no',
          label: 'NO',
          thStyle: { width: '60px', textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'work_type',
          label: 'ÇALIŞMA SINIFI',
          thStyle: { textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'job',
          label: 'İŞ',
          thStyle: { textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'created',
          label: 'TARİH',
          thStyle: { textAlign: 'left', verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: '#',
          thStyle: { textAlign: 'right', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'services.id AS id',
          '0 AS service_no',
          'services.related_person AS related_person',
          'services.finished AS finished',
          'work_types.title AS work_type',
          'jobs.title AS job',
          'cities_out1.title AS city_out1',
          'cities_out2.title AS city_out2',
          'services.created AS created',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      const data = this.$store.getters['services/getServices']
      return data
    },
    dataCount() {
      return this.$store.getters['services/getServicesCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    searchData() {
      this.dataQuery.or_like = {
        'customers.company': this.search,
        'work_types.title': this.search,
        'jobs.title': this.search,
      }
      this.getDataList()
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.search = ''
      this.getDataList()
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('services/servicesList', this.dataQuery)
    },
  },
}
</script>

<style scoped>

</style>
